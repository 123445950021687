var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-account"},[_vm._m(0),_c('div',{staticClass:"container-dhh va-row"},[_vm._m(1),_c('div',{staticClass:"flex md12 xs12 no-padding"},[_c('div',{staticClass:"ui-grid"},[_c('div',{staticClass:"my-data-table va-row",attrs:{"id":"cards"}},[_c('div',{staticClass:"flex xs12 no-padding fiscal-notes"},[_c('div',{staticClass:"body-spacing"},[(_vm.filteredList.length === 0)?_c('div',{staticClass:"va-row empty-area"},[_vm._m(2)]):_vm._e(),(_vm.filteredList.length > 0)?_c('div',{staticClass:"va-row"},[_c('div',{staticClass:"sets-list table-list-simple va-row"},[_vm._m(3),_c('div',{staticClass:"products-list"},[_c('div',{staticClass:"va-row"},_vm._l((_vm.filteredList),function(item){return _c('div',{key:item.id,staticClass:"lines"},[_c('div',{staticClass:"va-row"},[_c('div',{staticClass:"flex md2 item book-cover padding-list-book"},[_c('img',{attrs:{"src":item.thumbnail,"alt":"","width":"150"}})]),_c('div',{staticClass:"flex md8 item text-left book-content"},[_c('div',[_c('h4',[_c('span',{staticClass:"nome-da-marca"},[_vm._v(_vm._s(item.brand.name))]),_c('br'),_vm._v(_vm._s(item.name)+" ")]),_c('div',{staticClass:"description align-text-description md2",domProps:{"innerHTML":_vm._s(item.content_text_2)}})])]),_c('div',{staticClass:"flex md2 item action text-right"},[_c('router-link',{staticClass:"btn",attrs:{"to":{
																	name: 'manager-book',
																	params: { book: item },
																}}},[_vm._v(" VISUALIZAR ")])],1)])])}),0)])])]):_vm._e()])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-menu va-row"},[_c('div',{staticClass:"va-row"},[_c('div',{staticClass:"flex container-ddh-menu xs12 with-bottom va-row"},[_c('div',{staticClass:"flex xs12 no-padding"},[_c('h1',[_vm._v("Livros")])])])]),_c('div',{staticClass:"clearfix"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex md12 xs12 no-padding"},[_c('h4')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"empty-box"},[_c('div',{staticClass:"empty-box-header"},[_vm._v("LIVROS DISPONÍVEIS")]),_c('div',{staticClass:"empty-box-body"},[_c('b',[_vm._v("Nenhum livro cadastrado no momento. ")]),_c('br'),_vm._v(" Para solicitar um novo cadastro de livro envie um e-mail para: "),_c('a',{attrs:{"href":"mailto:escolas@dentroeduca.com.br"}},[_vm._v("escolas@dentroeduca.com.br")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"va-row header no-padding"},[_c('div',{staticClass:"flex item"},[_vm._v("LIVROS DISPONÍVEIS")])])
}]

export { render, staticRenderFns }